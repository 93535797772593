export default {
    methods: {
        HasPermission(permission){
            var user = this.$store.state.auth.user;
            if(!user || !user.employee || !user.employee.permissions || !Array.isArray(user.employee.permissions) || user.employee.permissions.filter(p => p == permission).length == 0)
            {
                return false
            }
            
            return true
        },
        IsProfileAdministrador() {
            var user = this.$store.state.auth.user;
            return user && user.type == "employee" && this.HasPermission("manuals-edit");
        }
    },
    computed: {
        LoggedUser() {
            var user = this.HasPermission("manuals") ? this.$store.state.auth.user : null;
			return user;
        }
    }
}